import { Component, Inject, LOCALE_ID, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { versions } from '../../../environments/versions';
import { AccountDesignService } from '../../route/admin/account-design/account-design.service';
import { StyleSettings } from '../../route/admin/account-design/account-design.types';
import { RuntimeEnvironmentService } from '../../core/runtime-environment.service';
import { PrincipalService } from '../../core/principal/principal.service';
import { VersionInfoService } from '../version-info/version-info.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { tap } from 'rxjs/operators';
import { AccountInterceptor } from '../../core/interceptors/account.interceptor';
import { CommonModule } from '@angular/common';


const version = (versions && versions.version) ? versions.version : '1.0.0';
const revision = (versions && versions.revision) ? '-' + versions.revision : '';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'rag-footer',
  templateUrl: './footer.component.html',
  styleUrls: [ './footer.component.scss' ],
})
export class FooterComponent
  implements OnInit, OnDestroy {

  styleSettings$: Observable<StyleSettings>;
  currentVersion: string;
  currentYear: number = new Date().getFullYear();
  showJiraCollector: boolean;
  isLoggedIn: boolean;

  constructor(
    private accountService: AccountDesignService,
    @Inject(LOCALE_ID) private locale: string,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
    private versionInfoService: VersionInfoService,
    private principalService: PrincipalService,
  ) {
    this.currentVersion = version + revision;
    this.styleSettings$ = this.accountService.getStyleSettings();
  }

  openVersionInfo(): void {
    this.versionInfoService.showDialog();
  }

  checkLink(link: string): boolean {
    if ( link == null ) {
      return false;
    }
    const linkTrim = link.trim();
    return linkTrim !== '';
  }

  ngOnInit(): void {

    this.principalService.isLogged$
      .pipe(tap(loggedIn => this.isLoggedIn = loggedIn))
      .pipe(tap(() => {

        const env = this.runtimeEnvironmentService.environment;
        if ( env?.enableJiraCollector !== true ) {
          return;
        }

        const disableInAccount = env?.disableJiraCollectorInAccount;
        if ( disableInAccount?.includes(AccountInterceptor.getAccountKey()) ) {
          return;
        }

        this.registerJiraCollector(true);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy() {
    destroySubscriptions(this);
  }

  private getLanguage() {
    const userLang = (this.locale || 'de');
    if ( userLang.length > 2 ) {
      return userLang.substr(0, 2);
    }
    return userLang;
  }

  private registerJiraCollector(enabled: boolean): void {

    // enable feedback collector for all instances, except iLearn24
    if ( this.showJiraCollector || !enabled ) {
      return;
    }

    // prevent duplicate
    this.showJiraCollector = true;

    // jQuery dependency for jira collector
    const jqueryJS = document.createElement('script');
    jqueryJS.type = 'text/javascript';
    jqueryJS.async = false;
    jqueryJS.src = 'https://code.jquery.com/jquery-3.7.0.min.js';
    jqueryJS.integrity = 'sha256-2Pmvv0kuTBOenSvLm6bvfBSSHrUJ+3A7x6P5Ebd07/g=';
    jqueryJS.crossOrigin = 'anonymous';

    const isLangDe = (this.getLanguage() === 'de');

    const collectorId = isLangDe ?
      // @see https://reflact-ag.atlassian.net//secure/ViewCollector!default.jspa?projectKey=TF&collectorId=313078ab
      'd12e5157' :
      // @see https://reflact-ag.atlassian.net//secure/ViewCollector!default.jspa?projectKey=TF&collectorId=befddbe2
      'b0165a50';
    const locale = isLangDe ? 'de_DE' : 'en_UK';
    const scriptUrl = 'https://reflact-ag.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/-3o5b4z/b/4/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js';

    // collector script
    const collectorJS = document.createElement('script');
    collectorJS.type = 'text/javascript';
    collectorJS.async = false;
    collectorJS.src = `${scriptUrl}?locale=${locale}&collectorId=${collectorId}`;

    // defer adding collector script until jQuery has loaded
    jqueryJS.onload = () => {
      body.appendChild(collectorJS);
    };

    // collector config
    const collectorConfig = document.createElement('script');
    collectorConfig.type = 'text/javascript';
    collectorConfig.async = false;
    const scriptNode = document.createTextNode(`
      function defer(method) {
        if (window.jQuery) {
          method();
        } else {
          setTimeout(function () { defer(method) }, 50);
        }
      }

      window.ATL_JQ_PAGE_PROPS = {
        "triggerFunction": function(showCollectorDialog) {
          jQuery("#myCustomTrigger").click(function(e) {
            e.preventDefault();
            showCollectorDialog();
          });
        },
        fieldValues: {
          recordWebInfo: '1',
          recordWebInfoConsent: ['1']
        },
        environment: function() {
          var loggedUser = window['loggedUser'] || {};
          var env = {
            accountKey: '${AccountInterceptor.getAccountKey()}',
            hostname: '${window.location.hostname}'
          };
          if (loggedUser.userId > 0) {
            env['userId'] = String(loggedUser.userId);
          }
          if (loggedUser.accountid > 0) {
            env['accountId'] = String(loggedUser.accountid);
          }
          return env;
        }
      };
    `);
    collectorConfig.appendChild(scriptNode);

    const body = document.body;
    body.appendChild(jqueryJS);
    body.appendChild(collectorConfig);
  }

}
