import { Component } from '@angular/core';
import { Moment } from 'moment';
import { AbstractPivotFilterComponent } from '../abstract-pivot-filter.component';
import {
  ColumnFilterDropdownOption,
  dropdownOptionsFactory,
  FilterOperator,
} from '../../../core/column-settings/column-filter.types';
import { DateHelper } from '../../../core/date.helper';
import { FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';


@Component({
  selector: 'rag-pivot-filter-date',
  templateUrl: './pivot-filter-date.component.html',
  styleUrls: [ './pivot-filter-date.component.scss' ],
})
export class PivotFilterDateComponent
  extends AbstractPivotFilterComponent<Moment | string> {

  static ACTIONS = {
    $eq: $localize`:@@pivot_filter_date_eq:equals ...`,
    $gte: $localize`:@@pivot_filter_date_gte:after or equal ...`,
    $lte: $localize`:@@pivot_filter_date_lte:before or equal ...`,
    $gt: $localize`:@@pivot_filter_date_gt:after ...`,
    $lt: $localize`:@@pivot_filter_date_lt:before ...`,
    $between: $localize`:@@pivot_filter_number_between:between (from - to)...`,
    $nextMonths: $localize`:@@pivot_filter_date_next_months:within the next ... month(s)`,
    $nextDays: $localize`:@@pivot_filter_date_next_days:within the next ... day(s)`,
    $previousMonths: $localize`:@@pivot_filter_date_previous_months:within the previous ... month(s)`,
    $previousDays: $localize`:@@pivot_filter_date_previous_days:within the previous ... day(s)`,
  };
  actionOptions: ColumnFilterDropdownOption[];
  regex: RegExp;
  pattern: string | RegExp = null;

  static RE_SINGLE_NUMBER = '^\\d*$';
  constructor(
    private formBuilder: FormBuilder,
  ) {
    super();

    this.defaultAction = FilterOperator.GTE;
    this.actions = Object.keys(PivotFilterDateComponent.ACTIONS);
    this.actionOptions = dropdownOptionsFactory(PivotFilterDateComponent.ACTIONS);

    this.dateFormGroup = this.formBuilder.group({
      start: [],
      end: [],
    });

    this.dateFormGroup.valueChanges.pipe(map(_ => {
      const startDate = DateHelper.toMoment(this.dateFormGroup.controls['start'].value);
      if (startDate != null) {
        startDate.startOf('day');
      }
      const endDate = DateHelper.toMoment(this.dateFormGroup.controls['end'].value);
      if (endDate != null) {
        endDate.endOf('day');
      }
      if (startDate != null && endDate != null) {
        this.value = startDate + ',' + endDate;
        this.updateValue();
      }
    }))
      .subscribe();
    this.pattern = PivotFilterDateComponent.RE_SINGLE_NUMBER;
    this.regex = new RegExp(this.pattern);
  }

  cleanValue(value: Moment | string): Moment | string {

    const action = this.action;
    switch (action) {
      case FilterOperator.NEXT_DAYS:
      case FilterOperator.NEXT_MONTHS:
      case FilterOperator.PREVIOUS_DAYS:
      case FilterOperator.PREVIOUS_MONTHS:
        if (typeof (value) === 'string') {
          const intValue = parseInt(value);
          if (isNaN(intValue)) {
            return null;
          }
          return `${intValue}`;
        }
        if (typeof (value) === 'number') {
          return `${value}`;
        }
        return null;
    }

    if (typeof value === 'string') {
      if ( this.pattern && !(new RegExp(this.pattern)).test(value) ) {
        // if the value does not match our pattern
        value = null;
      }
      return value;
    }

    value = DateHelper.toMoment(value);
    if ( !DateHelper.isValid(value) ) {
      value = null;
    }
    return value;
  }

  setAction(action: FilterOperator) {
    this.action = action;
    this.value = this.cleanValue(this.value);
    this.cancelDebounce();
    this.updateAction();
  }

  getActionText(): string {
    return PivotFilterDateComponent.ACTIONS[this.action];
  }

  numberMatchRegex(): boolean {
    if (this.value === '' || this.value == null) {
      return true;
    }

    if (this.regex == null) {
      return true;
    }
    return this.regex.test(String(this.value));
  }
}
