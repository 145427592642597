<ng-template
  #tplNoData
>
  <div *ngIf="hasResponse" class="no-data">
    <span i18n="@@certificates_no_data">Sorry, no certificate was found!</span>
  </div>
</ng-template>

<ng-container
  *ngIf="hasData; else tplNoData"
>
  <rag-page-header *ngIf="!embedded">
    <div class="page-header-title">
      <span i18n="@@header_nav_student_certificates">My certificates</span>
    </div>
  </rag-page-header>

  <div class="certificates-wrapper" [@.disabled]="disableAnimations$ | async">
    <div [ngClass]="{'certificates-container': !embedded, 'certificates-container-embedded': embedded}">

      <div class="certificate-filter">
        <mat-form-field *ngIf="!embedded"
                        appearance="outline"
                        class="filter-element">
          <mat-label>
            <span i18n="@@filter_Curriculum">Filter by title</span>
          </mat-label>
          <input
            (change)="applyFilter()" (keyup)="typeahead()"
            [(ngModel)]="filterState.ts"
            i18n-placeholder="@@enter_text_to_filter_curricula"
            id="filter-box"
            matInput
            placeholder="Enter text to filter curricula"/>
        </mat-form-field>
        <!-- Date Validity -->
        <mat-form-field
          *ngIf="!embedded"
          appearance="outline"
          class="filter-assignment filter-element"
        >
          <mat-label>
            <span i18n="@@placeholder_validity">Validity</span>
          </mat-label>
          <mat-select
            (selectionChange)="applyFilter()"
            [(ngModel)]="filterState.isValid"
          >
            <mat-option [value]="''">
              <span i18n="@@select_all">All</span>
            </mat-option>
            <mat-option [value]="'newest'">
              <span i18n="@@select_newest">Only newest</span>
            </mat-option>
            <mat-option [value]="'valid'">
              <span i18n="@@select_still_valid" >Currently valid</span>
            </mat-option>
            <mat-option [value]="'expired'">
              <span i18n="@@select_expired">Validity expired</span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          (click)="toggleViewMode()"
          *ngIf="!embedded && hasContent && !filterState.cv"
          class="place-right"
          mat-button>
            <mat-icon color="primary" svgIcon="view-module"></mat-icon>
            <span i18n="@@certificate_cards_view">Cards View</span>
        </button>

        <button (click)="toggleViewMode()" *ngIf="!embedded && hasContent && filterState.cv" class="place-right"
                mat-button>
          <mat-icon color="primary" svgIcon="view-list"></mat-icon>
          <span i18n="@@global_list_view">List view</span>
        </button>
      </div>
      <div *ngIf="hasContent && !filterState.cv" class="table-wrapper">

        <table @listAnimation (matSortChange)="sortData($event)" [dataSource]="certificatesDataSource" mat-table matSort>
          <ng-container matColumnDef="select">
            <th *matHeaderCellDef class="selectCheckbox" mat-header-cell>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [disabled]="numRowsDownloadEnable === 0"
                            [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
            </th>

            <td *matCellDef="let row" mat-cell>
              <mat-checkbox (change)="$event ? selection.toggle(row) : null"
                            (click)="$event.stopPropagation()"
                            [checked]="selection.isSelected(row)"
                            [disabled]="isCheckBoxDisabled(row)">
              </mat-checkbox>
            </td>
            <td *matFooterCellDef mat-footer-cell>
              <button (click)="downloadPdfCertificates()" [disabled]="numRowsDownloadEnable === 0 || !selection.hasValue()"
                      mat-button>
                <mat-icon svgIcon="download"></mat-icon>
              </button>
            </td>
          </ng-container>
          <!-- Curriculum Column -->
          <ng-container matColumnDef="curriculum">
            <th *matHeaderCellDef mat-header-cell
                mat-sort-header="curriculum">
              <span i18n="@@certificates_table_header_curriculum" >Curriculum</span>
            </th>
            <td *matCellDef="let certificate" mat-cell>
              <rag-clamp [clamp]="3" [input]="certificate.curriculum"></rag-clamp>
            </td>
            <td *matFooterCellDef mat-footer-cell>

            </td>
          </ng-container>

          <!-- validSince Column -->
          <ng-container matColumnDef="validSince">
            <th *matHeaderCellDef mat-header-cell
                mat-sort-header="validSince">
              <span i18n="@@certificates_table_header_valid_since">valid since</span>
            </th>
            <td *matCellDef="let certificate" mat-cell>
              {{(certificate.validSince === null || certificate.validSince === undefined) ? '—' : certificate.validSince| formatDate}}
            </td>
            <td *matFooterCellDef  mat-footer-cell>
              <span i18n="@@certificates_table_header_Elements">Elements:</span>
            </td>
          </ng-container>

          <!-- validUntil Column -->
          <ng-container matColumnDef="validUntil">
            <th *matHeaderCellDef mat-header-cell
                mat-sort-header="validUntil">
             <span i18n="@@certificates_table_header_valid_until" >valid until</span>
            </th>
            <td *matCellDef="let certificate" mat-cell>
              {{(certificate.validUntil === null || certificate.validUntil === undefined) ? '—' : certificate.validUntil  | formatDate}}
            </td>
            <td *matFooterCellDef mat-footer-cell>{{certificatesDataSource.filteredData.length}}
              of {{certificatesDataSource.data.length}}</td>
          </ng-container>


          <ng-container matColumnDef="options">
            <th *matHeaderCellDef mat-header-cell></th>
            <td *matCellDef="let certificate" mat-cell>
              <div>
                <button (click)="showIterationDetails($event, certificate.curid, certificate.iteration)"
                        *ngIf="certificate.templateAvailable || certificate.certUploaded"
                        mat-icon-button>
                  <mat-icon aria-label="Information certificate" svgIcon="information"></mat-icon>
                </button>
                <button (click)="generateCertificate(certificate.curid, certificate.iteration)"
                        *ngIf="certificate.templateAvailable || certificate.certUploaded"
                        [disabled]="certificate.certBlocked || (!certificate.availableForUser && !certificate.certUploaded)"
                        i18n-matTooltip="@@download"
                        mat-icon-button
                        matTooltip="Download">
                  <mat-icon svgIcon="download"></mat-icon>
                </button>
                <span *ngIf="(certificate.validUntil > 0) && (certificate.validUntil < today)" class="expired"
                      i18n="@@certificate_Expired">Expired</span>
              </div>
            </td>
            <td *matFooterCellDef mat-footer-cell></td>
          </ng-container>

          <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
          <tr class="fadeIn" *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
          <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"
              [ngClass]="{'hidden':!(certificatesDataSource.filteredData.length)}">
          </tr>-->
        </table>

        <a (click)="downloadPdfCertificates()" *ngIf="hasContent && !embedded"
           [disabled]="numRowsDownloadEnable === 0 || !selection.hasValue()" class="downloadIcon"
           color="primary"
           mat-flat-button>
          <mat-icon svgIcon="download"></mat-icon>
          <span i18n="@@download">Download</span>
        </a>
      </div>
      <ng-container
        *ngIf="hasResponse && this.certificatesDataSource?.data?.length === 0"
        [ngTemplateOutlet]="tplNoData"
      ></ng-container>
      <!--Content Card View -->
      <div
        *ngIf="hasContent && filterState.cv"
        [ngClass]="{'card-container-notfullscreen': !fullScreenSizeEnabled, 'card-container-fullscreen': fullScreenSizeEnabled}"
        class="card-container" @cardAnimation>

        <ng-container *ngFor="let certificate of searchedContents">

          <rag-certificate-card
            *ngIf="permissionStates.showNewUserInterfaceCards"
            (detailsClicked)="showIterationDetails($event, certificate.curid, certificate.iteration)"
            (downloadClicked)="generateCertificate(certificate.curid, certificate.iteration)"
            [certificate]="certificate"
          ></rag-certificate-card>

          <rag-certificate-card-v2
            *ngIf="!permissionStates.showNewUserInterfaceCards"
            (detailsClicked)="showIterationDetails($event, certificate.curid, certificate.iteration)"
            (downloadClicked)="generateCertificate(certificate.curid, certificate.iteration)"
            [certificate]="certificate"
          ></rag-certificate-card-v2>

        </ng-container>
      </div>
    </div>
  </div>
</ng-container>
