<rag-dialog-header>
  <span>{{getDialogTitle()}}</span>
</rag-dialog-header>

<div matDialogContent>
  <form [formGroup]="form">
    <div class="flex">
      <mat-form-field appearance="outline" *ngIf="!isModuleDisabled()">
        <mat-label i18n="@@global_module">Module</mat-label>
        <mat-select [formControlName]="'module'" data-cy="selectModule">
          <mat-option [value]="10" *ngIf="!this.data.defaultTemplateMode" data-cy="selectModuleUsers">
            <span i18n="@@global_users">Users</span>
          </mat-option>
          <mat-option [value]="11" data-cy="selectModuleContent">
            <span i18n="@@admin_lu_header">Learning content</span>
          </mat-option>
          <mat-option [value]="14" data-cy="selectModuleCurriculum">
            <span i18n="@@admin_content_content_curriculum">Curriculum</span>
          </mat-option>
          <mat-option [value]="15" data-cy="selectModuleEvents">
            <span i18n="@@header_nav_admin_offline">Events</span>
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!isElementDisabled()">
        <mat-label i18n="@@global_on_element">On element</mat-label>
        <input
          type="text"
          matInput
          [formControlName]="'item'"
          [matAutocomplete]="auto"
          i18n-placeholder="@@global_on_element"
          placeholder="On element"
          data-cy="inputOnElement"
        >
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="renderElement">
          <ng-container
            *ngFor="let item of filteredItems$ | async"
          >
            <mat-option [value]="item" (click)="onItemSelected(item)" data-cy="autocompleteOption">
              <span>{{item.systemName | localeText}}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!isEventDisabled()">
        <mat-label i18n="@@admin_offline_notifications_column_eventName">Event</mat-label>
        <mat-select [formControlName]="'event'" data-cy="selectEvent" required>
          <mat-option
            *ngFor="let event of notificationsOfFilteredEvents$ | async"
            [value]="event"
            [attr.data-cy]="'autocompleteOption'+'-'+event.eventId"
          >{{getEventTitle(event)}}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="!isSubTargetObjectDisabled()">
        <mat-label i18n="@@global_process_step">Process step</mat-label>
        <mat-select [formControlName]="'subTarget'" data-cy="selectTarget">
          <ng-container *ngIf="(subTargetsLoading$ | async) else selectSubTarget">
            <mat-progress-spinner></mat-progress-spinner>
          </ng-container>
          <ng-template #selectSubTarget>
            <mat-option data-cy="selectTargetOption"
              *ngFor="let subTarget of filteredSubTargets$ | async"
              [value]="subTarget"
            >
              <span>{{subTarget.title}}</span>
            </mat-option>
          </ng-template>
        </mat-select>
      </mat-form-field>
    </div>
  </form>
</div>

<div matDialogActions>

  <button
    mat-stroked-button
    type="button"
    [matDialogClose]="null"
    i18n="@@global_cancel"
    data-cy="buttonCancel"
  >Cancel</button>

  <button
    mat-flat-button
    type="button"
    color="primary"
    (click)="onSubmit()"
    [disabled]="saveButtonDisabled$ | async"
    data-cy="buttonSave"
  >{{saveText}}</button>

</div>
