import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { map, tap } from 'rxjs/operators';
import { ApiUrls } from 'src/app/core/api.urls';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { DidReceiveResponseHandler } from 'src/app/rag-layout/widgets/widget-wrapper/widget-wrapper.types';
import { ContentService } from '../../../core/content/content.service';
import { ContentOverviewComponentFilter } from '../../../core/content/content.types';
import { ModalDialog } from '../../../core/modal-dialog';
import { ViewHelper } from '../../../core/view-helper';
import { CertificatesInfoComponent } from './certificates-info/certificates-info.component';
import { CertificatesService } from './certificates.service';
import { Certificate, CurStatusItem } from './certificates.types';
import { CardGrowOnEnterDynamic, ListItemsFadeIn } from '../../../core/animations';
import { MediaMatchService } from '../../../core/media-match.service';
import { ImageableContentReference } from 'src/app/core/core.types';
import { AccountDesignService } from '../../admin/account-design/account-design.service';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { PermissionStates } from '../../../core/principal/permission.states';

@Component({
  selector: 'rag-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: [ './certificates.component.scss' ],
  animations: [
    ListItemsFadeIn,
    CardGrowOnEnterDynamic,
  ],
})
export class CertificatesComponent
  implements OnInit, OnDestroy {

    @Input() didReceiveResponse: DidReceiveResponseHandler;
    @Input() embedded = false;

    allCertificate: Certificate[] = [];
  certificatesDataSource = new MatTableDataSource<Certificate>();
  displayedColumns: string[] = [ 'select', 'curriculum', 'validSince', 'validUntil', 'options' ];
  filterState = new ContentOverviewComponentFilter('CertificatesComponent');
  hasContent = false;
  hasData = false;
  // by default there is no response from server received yet
  hasResponse = false;
  infoIsDisplayed = false;
  isFiltering: boolean;
  numRowsDownloadEnable: number;
  searchedContents: Certificate[];
  selection = new SelectionModel<Certificate>(true, []);
  sortedContents: Certificate[];
  titleTypeaheadTimer = null;
  today: number;
  userId: number;
  validUntil: number;
  disableAnimations$ = this.mediaMatch.mediaQueryListeners$['prefers-reduced-motion'];
  fullScreenSizeEnabled = false;
  permissionStates: PermissionStates;
  private _maxItems: number;

  constructor(
    private certificatesService: CertificatesService,
    private dialog: ModalDialog,
    private location: Location,
    private principalService: PrincipalService,
    private route: ActivatedRoute,
    private router: Router,
    private serializer: UrlSerializer,
    private services: CertificatesService,
    private mediaMatch: MediaMatchService,
    private accountDesignService: AccountDesignService
  ) {

    if ( this.route.snapshot.data.certificates ) {
      this.hasResponse = true;
      this.allCertificate = this.route.snapshot.data.certificates;
      this.setTableData(this.allCertificate);
      this.checkRowsDownloadEnable();
    }
  }

  ngOnInit() {
    this.principalService.fetchUserData()
      .subscribe(principal => {
        this.userId = principal.userId;
      });

    this.principalService.permissionStates$
      .pipe(tap(permissionStates => this.permissionStates = permissionStates))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    if ( this.embedded ) {
      this.filterState.fromDefaults();
      this.certificatesService.getCertificates(true).subscribe(_certificates => {
        this.hasResponse = true;
        this.allCertificate = _certificates.filter(c => c.validUntil == null || c.validUntil > Date.now());
        this.setTableData(this.allCertificate);
        this.checkRowsDownloadEnable();
        this.applyFilter();
        // notify all observers
        if ( this.didReceiveResponse ) {
          this.didReceiveResponse({
            hasContent: this.allCertificate.length > 0,
          });
        }
      });
    } else {
      this.filterState.fromStorage();
      this.filterState.parseParams(this.route.snapshot.queryParamMap);
      this.applyFilter();
      this.route.queryParamMap
        .pipe(map(params => {
          this.filterState.parseParams(params);
          this.applyFilter();
        }))
        .subscribe();
      // notify all observers
      if ( this.didReceiveResponse ) {
        this.didReceiveResponse({
          hasContent: this.searchedContents.length > 0,
        });
      }
    }
    this.today = new Date().getTime();
    /*// show only valid certificates with first view
    this.selectValidity = 'valid';
    this.applyFilter();*/

    this.accountDesignService.fullScreenSizeEnabled$
      .pipe(tap(fullScreenSizeEnabled => {
        this.fullScreenSizeEnabled = fullScreenSizeEnabled;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  get maxItems(): number {
    return this._maxItems;
  }

  @Input()
  set maxItems(value: number) {
    this._maxItems = value;
    this.applyFilter();
  }

  applyFilter(): void {
    this.hasData = (this.allCertificate?.length > 0);
    this.searchedContents = this.services.filterContent(this.filterState, this.allCertificate, this.filterState.isValid);
    this.setTableData(this.searchedContents);

    this.isFiltering = true;
    this.selection.clear();

    this.applySorting();
  }

  /**
   * Sort by title or dueDate in card view mode
   */
  applySorting(replaceUrlState: boolean = true) {
    this.sortData({
      active: this.filterState.es,
      direction: 'desc',
    });
    // encode the filter as query params
    if ( replaceUrlState ) {
      this.persistState();
    }
  }

  checkRowsDownloadEnable() {
    this.numRowsDownloadEnable = 0;
    const certificates: Certificate[] = this.certificatesDataSource.filter == null ?
      this.certificatesDataSource.data : this.certificatesDataSource.filteredData;
    certificates.forEach(row => {
        if ( !this.isCheckBoxDisabled(row) ) {
          this.numRowsDownloadEnable++;
        }
      },
    );
    this.prepareContent();
  }

  compareCurriculum(curriculum1: string, curriculum2: string, isAsc: boolean) {
    return (isAsc ? -1 : 1) * curriculum1.localeCompare(curriculum2);
  }

  compareDates(date1: number, date2: number, isAsc: boolean) {
    const _date1 = date1 || Number.MAX_VALUE;
    const _date2 = date2 || Number.MAX_VALUE;
    return (isAsc ? -1 : 1) * (_date1 - _date2);
  }

  downloadPdfCertificates() {
    const userId = this.userId;

    const targetObjects = this.selection.selected
      .map(entry => ({userId, curriculumId: entry.curid, iteration: entry.iteration}));
    this.certificatesService.downloadPdfCertificates(userId, targetObjects);
  }

  generateCertificate(curid: number, iteration: number) {
    window.location.href = ApiUrls.getKey('GenerateCertificate')
      .replace('{userId}', String(this.userId))
      .replace('{curId}', String(curid))
      .replace('{iteration}', String(iteration))
      .replace('{date}', String(new Date().getTime()));
//    this.certificatesService.generateCertificate(this.userId, curid, iteration);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected === 0 ? false : numSelected === this.numRowsDownloadEnable;
  }

  isCheckBoxDisabled(row: Certificate): boolean {
    if ( !(row.templateAvailable || row.certUploaded) ) {
      // no template available and nothing has been uploaded
      return true;
    } else if ( row.certBlocked ) {
      // download has been blocked by a controller
      return true;
    } else if ( !row.availableForUser ) {
      // some magic checkbox has been unchecked
      // @see https://tasks.reflact.com/browse/TRAINANG-1133
      return true;
    }
    return false;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    const certificates: Certificate[] = this.certificatesDataSource.filter == null ?
      this.certificatesDataSource.data :
      this.certificatesDataSource.filteredData;
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      certificates.forEach(row => {
          if ( !this.isCheckBoxDisabled(row) ) {
            this.selection.select(row);
          }
        },
      );
    }
  }

  prepareContent(): void {
    // because of the rendering process there are much more calls than objects --> this forEach() limits the calls to one time
    this.allCertificate.forEach(certificate => {
      const $view = ViewHelper.getViewData(certificate);
      const content = $view.curriculum ?? {} as ImageableContentReference;
      content.imageUrl = ContentService.getImage(content);
    });
  }

  showIterationDetails($event: any, curid: number, iteration: number) {
    // $event.preventDefault();
    // $event.stopImmediatePropagation();

    if ( this.infoIsDisplayed ) {
      return;
    }
    this.infoIsDisplayed = true;

    // let dataInfo: CertIterationDetails;
    this.certificatesService
      .getCertIterationDetails(this.userId, curid, iteration)
      .subscribe(certIterationDetails => {
          // TODO mapping
          let i = 0;
          let item: CurStatusItem;
          certIterationDetails.iteration = iteration;
          certIterationDetails.curstatus.items = [];
          do {
            item = certIterationDetails.curstatus['item' + i++];
            if ( item != null ) {
              certIterationDetails.curstatus.items.push({
                displayStatus: item.displayStatus,
                title: item.title,
                finishDate: item.finishDate,
                result: item.result,
              });
            }
          } while ( item != null );

          // TODO end
          this.dialog.openModal(CertificatesInfoComponent, {
            data: certIterationDetails,
            panelClass: 'max-width-dialog-container',
            minWidth: '50vw',
            maxWidth: '90%',
            maxHeight: '70%',
          }).afterClosed().subscribe(() => {
            this.infoIsDisplayed = false;
          });
        },
        error => {
          console.log(error.message);
          console.log(error.error);
          return;
        });
  }

  sortData(sort: Sort) {

    this.searchedContents = this.certificatesDataSource.data;

    if ( !sort.active || sort.direction === '' ) {

      this.sortedContents = this.searchedContents;

    } else {

      const data = this.searchedContents.slice();

      this.sortedContents = data.sort((a, b) => {
        const isAsc = sort.direction === 'asc';
        switch ( sort.active ) {
          case 'curriculum':
            return this.compareCurriculum(a.curriculum, b.curriculum, isAsc);
          case 'validSince':
            return this.compareDates(a.validSince, b.validSince, isAsc);
          case 'validUntil':
            return this.compareDates(a.validUntil, b.validUntil, isAsc);
          default:
            return 0;
        }
      });
    }

    if ( (this._maxItems > 0) && (this.sortedContents.length > this._maxItems) ) {
      this.sortedContents.splice(this._maxItems);
    }

    this.setTableData(this.sortedContents);
    this.searchedContents = this.sortedContents;
  }

  toggleViewMode() {
    this.filterState.cv = !this.filterState.cv;
    this.persistState();

  }

  // delayed search
  typeahead() {
    if ( this.titleTypeaheadTimer != null ) {
      clearTimeout(this.titleTypeaheadTimer);
    }
    this.titleTypeaheadTimer = setTimeout(() => {
      this.applyFilter();
      clearTimeout(this.titleTypeaheadTimer);
    }, 400);
  }

  private persistState() {
    const validObject = this.filterState.asValidObject();
    const queryUrl = this.router.createUrlTree([], { queryParams: validObject });
    this.location.go(this.serializer.serialize(queryUrl));
  }

  private setTableData(data: Certificate[]): void {
    this.certificatesDataSource.data = data;
    this.hasContent = this.certificatesDataSource.data != null && this.certificatesDataSource.data.length > 0;
  }

}
