import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { isNumber } from 'lodash';
import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { catchError, finalize, map, switchMap, tap } from 'rxjs/operators';
import { ApiResponse } from 'src/app/core/global.types';
import { OfflineInvitationConstants } from 'src/app/core/learner-account/learner-account.types';
import { OfflineContent } from '../../../../core/admin-offline.types';
import { ApiUrls } from '../../../../core/api.urls';
import { CachedSubject } from '../../../../core/cached-subject';
import { IcalService } from '../../../../core/ical/ical.service';
import { Ical } from '../../../../core/ical/ical.types';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageKey } from '../../../../core/info/info.types';
import { LanguageHelper } from '../../../../core/language.helper';
import { Catalogs } from '../../../../core/catalog/catalog.types';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { CatalogService } from 'src/app/core/catalog/catalog.service';
import { UrlHelper } from '../../../../core/url.helper';
import { Identifiable, Titleable } from 'src/app/core/core.types';


@Injectable({ providedIn: 'root' })
export class ContentOfflineService {

  readonly needsReload$: Observable<void>;
  private _inputDisabled = new CachedSubject<boolean>(false);
  private _needsReload = new EventEmitter<void>();

  constructor(
    private http: HttpClient,
    private icalService: IcalService,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private catalogService: CatalogService
  ) {
    this.needsReload$ = this._needsReload.asObservable();
  }

  get inputDisabled$(): Observable<boolean> {
    return this._inputDisabled.withoutEmptyValuesWithInitial();
  }

  bookEventSchedule(
    offlineContent: OfflineContent.Event,
    offlineEvent: OfflineContent.EventSchedule,
    bookingUUID: string,
    isReservation: boolean): Observable<Catalogs.CatalogBooking> {

    const offlineContentId = offlineEvent?.contentId;
    const offlineEventId = offlineEvent?.id;
    if ( this._inputDisabled.value || !((offlineContentId > 0) && (offlineEventId > 0)) ) {
      return EMPTY;
    }

    this._inputDisabled.next(true);

    return this.catalogService.bookEventSchedule(offlineContentId, offlineEventId, bookingUUID, isReservation)
      .pipe(catchError(err => {
        this.infoService.showSnackbar(MessageKey.OFFLINE_CNT.LEARNER.EVENT_BOOKING_FAILED, InfoType.Error);
        return EMPTY;
      }))
      .pipe(switchMap( booking => {
        if (!isReservation) {
          if ( offlineContent.assignmentModeForPrincipal === 'take' ) {
            this.infoService.showSnackbar(MessageKey.OFFLINE_CNT.LEARNER.EVENT_BOOKING_SUCCESS, InfoType.Success);
          } else {
            this.infoService.showSnackbar(MessageKey.OFFLINE_CNT.LEARNER.EVENT_BOOKING_REQUEST_SUCCESS, InfoType.Success);
          }
        }
        const finishBookingUrl = ApiUrls.getKey('CatalogFinishBooking');
        return this.http.post<ApiResponse<any>>(finishBookingUrl, {bookingIds: [booking.id]}  ).pipe(map(_ => booking));
      }))
      .pipe(finalize(() => {
        this._needsReload.emit();
      }));
  }

  cancelBookingByEventSchedule(eventId: number, eventScheduleId: number, isEventBooked: boolean): Observable<unknown> {

    this._inputDisabled.next(true);
    return this.catalogService.cancelBookingByEventSchedule(eventId, eventScheduleId)
      .pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 404) {
          // the payment module is not activated
          this.infoService.showMessage(
            $localize`:@@payment_module_not_activated:The booking cannot be canceled right now. Please contact the customer support for assistance.`,
            { infoType: InfoType.Error });
        } else {
          this.infoService.showMessage(
            $localize`:@@general_error:The last operation failed. Please try again later.`,
            { infoType: InfoType.Error });
        }

        return EMPTY;
      }))
      .pipe(tap(() => {
        let cancelMessage = $localize`:@@catalog_cancel_booking_confirm:This booking has been canceled`;
        if (!isEventBooked) {
          cancelMessage = $localize`:@@catalog_cancel_request_confirm:This request has been canceled`;
        }
        this.infoService.showMessage(cancelMessage, {infoType: InfoType.Success});
        this._needsReload.emit();
      }))
      .pipe(finalize(() => {
        this._inputDisabled.next(false);
      }));
  }

  changeStatus(contentId: number, eventId: number, status: OfflineInvitationConstants): Observable<unknown> {
    if ( this._inputDisabled.value === true ) {
      // query already running
      return EMPTY;
    }

    this._inputDisabled.next(true);

    let url;
    let needsReload = true;
    switch ( status ) {
      case OfflineInvitationConstants.INV_STATE_CANCELLED:
        url = ApiUrls.getKey('AccountOfflineContentCancel');
        break;
      case OfflineInvitationConstants.INV_STATE_ACK:
        url = ApiUrls.getKey('AccountOfflineContentConfirm');
        break;
      case OfflineInvitationConstants.INV_STATE_DEC:
        url = ApiUrls.getKey('AccountOfflineContentDecline');
        break;
      default:
        needsReload = false;
        return EMPTY;
    }

    url = url
      .replace(/{offlineContentId}/, String(contentId))
      .replace(/{offlineEventId}/, String(eventId));

    return this.http.post<any>(url, null)
      .pipe(catchError(err => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        return EMPTY;
      }))
      .pipe(tap(() => {
        this.infoService.showSnackbar(MessageKey.GENERAL_SAVE_SUCCESS, InfoType.Success);
        if ( needsReload ) {
          this._needsReload.emit();
        }
      }))
      .pipe(finalize(() => this._inputDisabled.next(false)));
  }

  downloadEvent(offlineContent: OfflineContent.Event, offlineEvent: OfflineContent.EventSchedule): Observable<unknown> {
    return this.eventToIcal('event.ics', offlineContent, offlineEvent, false)
      .pipe(tap(file => FileSaver.saveAs(file)))
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        return EMPTY;
      }));
  }

  eventToIcal(
    fileName: string,
    offlineContent: Titleable & Identifiable,
    offlineEvent: OfflineContent.EventSchedule,
    addMacro: boolean = true,
  ): Observable<File> {

    if ( !(offlineContent && offlineEvent && (offlineEvent.eventDateUntil > offlineEvent.eventDate)) ) {
      return EMPTY;
    }

    // todo check if we can add time blocks to ICS
    let location;
    if ( offlineEvent.offlineContentType === OfflineContent.EventType.virco) {
      if ( offlineEvent.extLogin?.serverType === 'any' ) {
        if ( offlineEvent.extLogin?.url ) {
          location = offlineEvent.extLogin?.url;
        } else {
          location = '';
        }
      } else {
        const redirectUrl = '/run/offline/' + offlineContent.id;
        location = UrlHelper.getPublicRedirect(redirectUrl);
      }
    } else {
      if ( offlineEvent.location ) {
        let lastLength = 0;
        location = offlineEvent.location.name != null ? offlineEvent.location.name : '';
        if ( location.length > lastLength) {
 location += ' ('; lastLength = location.length;
}
        location += offlineEvent.location.address1 != null ? offlineEvent.location.address1 : '';
        if ( location.length > lastLength) {
 location += ', '; lastLength = location.length;
}
        location += offlineEvent.location.address2 != null ? offlineEvent.location.address2 : '';
        if ( location.length > lastLength) {
 location += ', '; lastLength = location.length;
}
        location += offlineEvent.location.zip != null ? offlineEvent.location.zip : '';
        if ( location.length > lastLength) {
 location += ' '; lastLength = location.length;
}
        location += offlineEvent.location.city != null ? offlineEvent.location.city : '';
        if ( location.length > lastLength) {
 location += ') - ';
}
        location += offlineEvent.location.info != null ? offlineEvent.location.info : '';
      } else {
        location = '';
      }
    }

    let description = LanguageHelper.objectToText(offlineEvent.description);
    if ( addMacro ) {
      location = '{LOCATION}';

      if (description.length > 0) {
        description = '{MESSAGE_TEXT}<br><br>====================<br><br>' + description;
      } else {
        description = '{MESSAGE_TEXT}';
      }
    }

    const eventItems: Ical.Event[] = [ {
      subject: LanguageHelper.objectToText(offlineContent.title),
      description,
      begin: moment(offlineEvent.eventDate),
      end: moment(offlineEvent.eventDateUntil),
      location,
    } ];

    return this.icalService.toICSFile(fileName, 'offline-event' + offlineEvent.id, eventItems);
  }

  emitInputDisabled(change: boolean) {
    this._inputDisabled.next(change);
  }

  joinEvent(offlineContentId: number, offlineEventId: number, url: string, startTime: number): void {
    const user = this.principalService.currentUser;
    this.doLoggingCall(offlineContentId, offlineEventId, user.userId, () => {
      var newWindow = window.open();
      newWindow.location = url;
      // window.open(url, '_blank');
    });
  }

  doLoggingCall(offlineContentId: number, offlineEventId: number, principalId: number, closure: () => void): void {
    const url = ApiUrls.getKey('LogAttendance')
      .replace('{contentId}', String(offlineContentId))
      .replace('{eventId}', String(offlineEventId))
      .replace('{principalId}', String(principalId));
    this.http.post<ApiResponse<any>>(url, null)
    .pipe(tap(_ => closure()))
    .pipe(catchError(() => {      
      // try to register the user attempt to get into the room every 5 seconds
      window.setTimeout( () => this.doLoggingCall(offlineContentId, offlineEventId, principalId, closure), 5 * 1000);      
      return EMPTY;
    }))
      .subscribe();
  }

  calculateDuration(eventSchedule: OfflineContent.EventSchedule): number {
    let hours = null;
    if ( isNumber(eventSchedule.eventDate) && isNumber(eventSchedule.eventDateUntil) ) {
      const beginDateTime = moment(eventSchedule.eventDate)
        .startOf('hour');
      const endDateTime = moment(eventSchedule.eventDateUntil)
        .startOf('minute');
      if ( endDateTime.minutes() > 0 ) {
        // round up to full hours
        endDateTime.startOf('hour').add(1, 'hours');
      }
      hours = endDateTime.diff(beginDateTime, 'hours');
    }
    if ( hours != null && hours < 0 ) {
      return;
    }
    if ( hours < 1 ) {
      hours = 1;
    } else if ( hours >= 24 ) {
      const workDays = Math.floor(hours / 24);
      hours = workDays * 8 + (hours % 24);
    }
    return hours;
  }

  private getBaseUrl() {
    return window.location.href.match(/(http(?:s)?:\/\/.+?)\/[#].*/)[1];
  }

}
