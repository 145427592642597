import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { CachedSubject } from '../../../../core/cached-subject';
import { FormBuilder, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, take } from 'rxjs/operators';
import { TextMacro, TextMacroSaveData, TextMacroUsageDetails } from '../admin-text-macros.types';
import { AdminTextMacrosService } from '../admin-text-macros.service';
import { InfoService } from '../../../../core/info/info.service';
import { InfoType, MessageConstants } from '../../../../core/info/info.types';
import { destroySubscriptions, takeUntilDestroyed } from '../../../../core/reactive/until-destroyed';
import { UsageDetails } from '../../admin-signatures/admin-signatures.types';

@Component({
  selector: 'rag-admin-text-macros-details',
  templateUrl: './admin-text-macros-details.component.html',
  styleUrls: ['./admin-text-macros-details.component.scss']
})
export class AdminTextMacrosDetailsComponent implements OnInit, OnDestroy {

  formGroup: FormGroup;
  pageHeaderTitle$: Observable<string>;
  saveButtonText$: Observable<string>;
  saveButtonDisabled$: Observable<boolean>;
  textMacro: TextMacro;
  usages: UsageDetails[];

  private _pageHeaderTitle$ = new CachedSubject<string>('');
  private _saveButtonText$ = new CachedSubject<string>('');
  private _saveButtonDisabled$ = new CachedSubject<boolean>(true);

  constructor(
    private adminTextMacrosService: AdminTextMacrosService,
    private formBuilder: FormBuilder,
    private infoService: InfoService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.pageHeaderTitle$ = this._pageHeaderTitle$.asObservable();
    this.saveButtonText$ = this._saveButtonText$.asObservable();
    this.saveButtonDisabled$ = this._saveButtonDisabled$.asObservable();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  ngOnInit(): void {
    this.route.data
      .pipe(map(data => this.updateRouteData(data)))
      .pipe(take(1))
      .subscribe();
  }

  onSave(): void {
    const saveData: TextMacroSaveData = {
      title: this.formGroup.get('title').value,
      description: this.formGroup.get('description').value,
      macro: this.formGroup.get('macro').value,
      content: this.formGroup.get('content').value,
      id: this.textMacro.id,
    };

    this.adminTextMacrosService.saveTextMacro(saveData)
      .pipe(switchMap(response => {
        this.textMacro = response.textMacro;
        this._pageHeaderTitle$.next(this.textMacro.name);
        this._saveButtonText$.next($localize`:@@global_save:Save`);
        this.infoService.showMessage(MessageConstants.API.SUCCESS, {
          infoType: InfoType.Success,
        });
        this.formGroup.markAsUntouched();
        this.formGroup.markAsPristine();
        return this.router.navigateByUrl('/admin/textMacros/details/' + this.textMacro.id);
      }))
      .pipe(catchError(() => this.infoService.showMessage(MessageConstants.API.ERROR, {
        infoType: InfoType.Error,
      })))
      .pipe(take(1))
      .subscribe();
  }

  protected noWhitespaceValidator(control: UntypedFormControl) {
    const controlValue: string = control.value ?? '';
    const hasNoWhitespace = controlValue.indexOf(' ') === -1;
    return hasNoWhitespace ? null : { whitespace: true };
  }

  private buildFormGroup(): void {
    this.formGroup = this.formBuilder.group({
      title: [ this.textMacro.name, [Validators.required] ],
      description: [ this.textMacro.description, [Validators.required] ],
      content: [ this.textMacro.content, [Validators.required] ],
      macro: [ this.textMacro.macro, [this.noWhitespaceValidator] ],
    });

    this.formGroup.statusChanges.pipe(map(_ => {
      this._saveButtonDisabled$.next(this.formGroup.invalid || this.formGroup.pristine);
    }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private updateRouteData(data): void {
    this.textMacro = data.data.textMacro;
    this.usages = data.data.usages;
    this.buildFormGroup();

    if ( !(this.textMacro.id > 0) ) {
      this._pageHeaderTitle$.next($localize`:@@admin_new_text_macro:New text macro`);
      this._saveButtonText$.next($localize`:@@general_create:Create`);
    } else {
      this._pageHeaderTitle$.next(this.textMacro.name);
      this._saveButtonText$.next($localize`:@@global_save:Save`);
    }
  }
}
