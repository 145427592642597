import { TableColumnBuilder } from '../../../../../component/table/table-column.builder';
import {
  TableColumnDataType,
  TableControllerTypes,
} from '../../../../../component/table/table-controller/table-controller.types';


export enum AdminOfflineNotificationStatus {
  enabled = 'enabled',
  expired = 'expired',
  disabled = 'disabled',
}

export interface AdminOfflineNotificationRow {
  actionId: number;
  enabled: AdminOfflineNotificationStatus;
  enabledEndDate: number;
  enabledStartDate: number;
  eventId: number;
  eventModuleId: number;
  eventName: string;
  excludedTargetGroups: string;
  notificationId: number;
  offset: string;
  targetGroups: string;
  title: string;
  urlCallId?: number;
}

export interface ContentOverviewColumnMenuData
  extends TableControllerTypes.ColumnMenuData<AdminOfflineNotificationRow> {
  menuItems: ContentOverviewColumnMenuItemMap;
}

export interface ContentOverviewColumnMenuItemMap
  extends TableControllerTypes.ColumnMenuItemMap {
  actionId: TableControllerTypes.ColumnMenuItem;
  actions: TableControllerTypes.ColumnMenuItem;
  checkBox: TableControllerTypes.ColumnMenuItem;
  enabled: TableControllerTypes.ColumnMenuItem;
  enabledEndDate: TableControllerTypes.ColumnMenuItem;
  enabledStartDate: TableControllerTypes.ColumnMenuItem;
  eventId: TableControllerTypes.ColumnMenuItem;
  eventModuleId: TableControllerTypes.ColumnMenuItem;
  eventName: TableControllerTypes.ColumnMenuItem;
  excludedTargetGroups: TableControllerTypes.ColumnMenuItem;
  offset: TableControllerTypes.ColumnMenuItem;
  targetGroups: TableControllerTypes.ColumnMenuItem;
  title: TableControllerTypes.ColumnMenuItem;
}

export const CONTENT_NOTIFICATION_COLUMN_DEFAULTS: ContentOverviewColumnMenuData = {
  startWith: ['checkBox'],
  endWith: [ 'actions' ],
  menuItems: {

    enabled: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('enabled')
      .withType(TableColumnDataType.dropdown)
      .withTitle($localize`:@@admin_offline_notifications_column_enabled:Enabled`)
      .withDropDownOptions({
        enabled: $localize`:@@global_enabled:enabled`,
        expired: $localize`:@@global_expired:expired`,
        disabled: $localize`:@@global_disabled:disabled`,
      })
      .withSelected()
      .build(),

    title: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('title')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@global_title:Title`)
      .withSelected()
      .build(),

    eventName: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('eventName')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@admin_offline_notifications_column_eventName:Event`)
      .withSelected()
      .build(),

    enabledStartDate: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('enabledStartDate')
      .withType(TableColumnDataType.date)
      .withTitle($localize`:@@admin_offline_notifications_column_enabled_from:Enabled from`)
      .withSelected(false)
      .build(),

    enabledEndDate: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('enabledEndDate')
      .withType(TableColumnDataType.date)
      .withTitle($localize`:@@admin_offline_notifications_column_enabled_until:Enabled until`)
      .withSelected(false)
      .build(),

    offset: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('offset')
      .withType(TableColumnDataType.text)
      .withTitle($localize`:@@admin_offline_notifications_column_offset:Offset`)
      .withSelected()
      .build(),

    targetGroups: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('targetGroups')
      .withType(TableColumnDataType.html)
      .withTitle($localize`:@@global_target_groups:Target groups`)
      .withSelected()
      .build(),

    excludedTargetGroups: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('excludedTargetGroups')
      .withType(TableColumnDataType.html)
      .withTitle($localize`:@@admin_offline_notifications_column_excludedTargetGroups:Target groups (excluded)`)
      .withSelected()
      .build(),

    actions: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('actions')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),

    checkBox: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('checkBox')
      .withType(TableColumnDataType.other)
      .withHiddenSelected()
      .build(),

    actionId: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('actionId')
      .withType(TableColumnDataType.number)
      .withTitle($localize`:@@global_notification_id:Notification ID`)
      .build(),

    eventId: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('eventId')
      .withType(TableColumnDataType.number)
      .withTitle($localize`:@@admin_offline_notifications_column_eventId:Event ID`)
      .build(),

    eventModuleId: TableColumnBuilder.start<AdminOfflineNotificationRow>()
      .withColumnId('eventModuleId')
      .withType(TableColumnDataType.number)
      .withTitle($localize`:@@admin_offline_notifications_column_eventModuleId:Module ID`)
      .build(),
  },
};
