<rag-table-column-menu
  #tableColumnMenu
  (evtColumnsChanged)="setColumns($event)"
  [menuData$]="columnMenuData$"
  menuContext="CtrlSingleUserDetailsCurriculumComponent@columnSettings"
  (evtMenuItemsChanged)="isColumnContextLoaded = true"
></rag-table-column-menu>

<mat-menu #parentActionsMenu>
  <ng-template matMenuContent let-row="row">
    <ng-container *ngIf="asParent(row) as parentRow">
      <a mat-menu-item *ngIf="curriculumId !== parentRow.$data.curriculumId"
         [routerLink]="getCurriculumDetailsUrl(parentRow)">
        <mat-icon svgIcon="beaker-outline"></mat-icon>
        <span i18n="@@ctrl_single_user_open_tab">Open in tab</span>
      </a>

      <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
           [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
           [matTooltipDisabled]="hasChangeableChildren(parentRow) && canChangeData">
        <button mat-menu-item type="button" [disabled]="!canChangeData || !hasChangeableChildren(parentRow)"
                (click)="onEditAccount(parentRow)">
          <mat-icon svgIcon="list-status"></mat-icon>
          <span i18n="@@ctrl_single_user_learning_state">Manage learning state</span>
        </button>
      </div>

      <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
           [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
           [matTooltipDisabled]="canChangeData || maySave(row)"
      >
        <button [disabled]="!canChangeData || !maySave(row)"
                mat-menu-item type="button"
                (click)="onCurrentIteration(parentRow)"
        >
          <mat-icon svgIcon="vector-polyline-edit" [class.mat-icon-disabled]="!canChangeData"></mat-icon>
          <span i18n="@@ctrl-single-user-edit-current-iteration">Edit current iteration</span>
        </button>
      </div>

      <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization"
           [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
           [matTooltipDisabled]="canChangeData || maySave(row)"
           *ngIf="hasValidity(row)"
      >
        <button [disabled]="!canChangeData || !maySave(row)"
                mat-menu-item type="button"
                (click)="onShowLatestValidity(parentRow)"
        >
          <mat-icon svgIcon="pencil-outline" [class.mat-icon-disabled]="!canChangeData"></mat-icon>
          <span i18n="@@ctrl-single-user-edit-latest-validity">Edit last validity</span>
        </button>
      </div>

      <a mat-menu-item (click)="onCurriculumSteering(parentRow)">
        <mat-icon svgIcon="history"></mat-icon>
        <span i18n="@@ctrl-single-user-unsuccessfull-iteration">Unsuccessful iterations</span>
      </a>

      <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization" [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
           [matTooltipDisabled]="maySave(parentRow) && canChangeData">
        <button mat-menu-item type="button" [disabled]="!canChangeData || !maySave(parentRow)" (click)="onSwitchPath(parentRow)">
          <mat-icon svgIcon="call-split"></mat-icon>
          <span i18n="@@curriculum_switch_path">Switch path</span>
        </button>
      </div>

      <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization" [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
           [matTooltipDisabled]="maySave(parentRow) && canChangeData">
        <button mat-menu-item type="button" [disabled]="!canChangeData || !maySave(parentRow)" (click)="onResetLock(parentRow)">
          <mat-icon svgIcon="lock-check-outline"></mat-icon>
          <span i18n="@@curriculum_reset_locks">Reset locks</span>
        </button>
      </div>

      <button
        mat-menu-item
        type="button"
        [matMenuTriggerFor]="runtimeActionsMenu"
      >
        <mat-icon svgIcon="calendar-outline"></mat-icon>
        <span i18n="@@ctrl_single_user_curriculum_actions_runtime">Runtime status</span>

        <mat-menu #runtimeActionsMenu>

          <button mat-menu-item type="button" (click)="onShowEvents(parentRow)">
            <mat-icon class="mat-icon-space" svgIcon="calendar-multiple-check"></mat-icon>
            <span i18n="@@ctrl_single_user_curriculum_planned_events">Planned events</span>
          </button>

          <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization" [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
               [matTooltipDisabled]="maySave(parentRow)">
            <button mat-menu-item type="button" [disabled]="!maySave(parentRow)" (click)="onRecalculateEvents(parentRow)">
              <mat-icon svgIcon="calendar-multiple-check" class="mat-icon-space"></mat-icon>
              <span i18n="@@ctrl_single_user_curriculum_events_recalculate">Recalculate events</span>
            </button>
          </div>

          <button mat-menu-item type="button" (click)="onShowNotifications(parentRow)">
            <mat-icon class="mat-icon-space" svgIcon="email-outline"></mat-icon>
            <span i18n="@@ctrl_single_user_curriculum_planned_notifications">Planned notifications</span>
          </button>

          <div matTooltip="Missing editing rights" i18n-matTooltip="@@ctrl_single_user_missing_authorization" [matTooltipPosition]="'left'" [matTooltipShowDelay]="1000"
               [matTooltipDisabled]="maySave(parentRow)">
            <button mat-menu-item type="button" [disabled]="!maySave(parentRow)" (click)="onRecalculateNotifications(parentRow)">
              <mat-icon svgIcon="email-outline" class="mat-icon-space"></mat-icon>
              <span i18n="@@ctrl_single_user_curriculum_notifications_recalculate">Recalculate notifications</span>
            </button>
          </div>

        </mat-menu>
      </button>
      <button
        *ngIf="isCurriculumAndStatusFixable(parentRow)"
        (click)="onFixStatus(parentRow)"
        mat-menu-item
        type="button"
      >
        <mat-icon svgIcon="arrow-u-down-left"></mat-icon>
        <span i18n="@@cur_ctr_fix_status">Fix status</span>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #childActionsMenu>
  <ng-template matMenuContent let-row="row">

    <button
      mat-menu-item
      type="button"
      [matMenuTriggerFor]="eventCourseActionsMenu"
      [disabled]="!maySave(row)"
    >
      <mat-icon svgIcon="file-document-edit-outline"></mat-icon>
      <span i18n="@@block_status">Module status</span>

      <mat-menu #eventCourseActionsMenu>

        <button
          (click)="onEditAccount(row)"
          type="button" mat-menu-item>
          <mat-icon svgIcon="pencil-outline"></mat-icon>
          <span i18n="@@global_edit">Edit</span>
        </button>
      </mat-menu>
    </button>


    <button
      *ngIf="getContextItemUrl(row) as detailsUrl"
      mat-menu-item
      type="button"
      [matMenuTriggerFor]="contextItemsActionsMenu"
      [disabled]="!maySave(row)"
    >
      <mat-icon svgIcon="school-outline"></mat-icon>
      <span i18n="@@ctrl_single_user_curriculum_status_course">Module details</span>

      <mat-menu #contextItemsActionsMenu>

        <a
          type="button" mat-menu-item
          [routerLink]="detailsUrl"
        >
          <mat-icon svgIcon="pencil-outline"></mat-icon>
          <span i18n="@@global_edit">Edit</span>
        </a>

      </mat-menu>

    </button>


    <button
      *ngIf="(moduleDetailsEnabled$ | async) === true && includesType([row], 'lms_course')"
      mat-menu-item
      type="button"
      [matMenuTriggerFor]="courseActionsMenu"
      [disabled]="!hasCourseActions(row)"
    >
      <mat-icon svgIcon="school-outline"></mat-icon>
      <span i18n="@@ctrl_single_user_curriculum_status_course">Module details</span>

      <mat-menu #courseActionsMenu>
        <ng-container *ngIf="getCourse(row) as course">

          <button *ngIf="isTodo(course)"
                  [disabled]="!mayOpenCourseInfos(course)" type="button" mat-menu-item (click)="openCourseAccount(course, row)">
            <mat-icon svgIcon="pencil-outline"></mat-icon>
            <span i18n="@@global_edit">Edit</span>
          </button>

          <button [disabled]="!maySave(row)" mat-menu-item type="button" (click)="onResetCourse(row)">
            <mat-icon svgIcon="undo-variant"></mat-icon>
            <span i18n="@@global_reset">Reset</span>
          </button>

          <button [disabled]="!mayReadScormLog(course)" type="button" mat-menu-item
                  (click)="onSessionDataDialog(course)">
            <mat-icon svgIcon="school"></mat-icon>
            <span i18n="@@ctrl_single_user_session_state">Session data</span>
          </button>

          <button [disabled]="!mayReadScormLog(course)" type="button" mat-menu-item
                  (click)="onScormLogDialog(course)">
            <mat-icon svgIcon="math-log"></mat-icon>
            <span i18n="@@ctrl_single_user_scorm_log">Scorm log</span>
          </button>

          <a [disabled]="!mayReadScormLog(course)" type="button" mat-menu-item
                  [routerLink]="getInteractionsUrl(course, row)">
            <mat-icon svgIcon="check-circle-outline"></mat-icon>
            <span i18n="@@global_interactions_evaluation">Interaction evaluation</span>
          </a>

          <button [disabled]="!mayReadScormLog(course)" type="button" mat-menu-item (click)="onDownloadInteractionsPDF(course)">
            <mat-icon svgIcon="download-outline"></mat-icon>
            <span i18n="@@ctrl_single_user_interactions_exp">Interaction PDF export</span>
          </button>

        </ng-container>
      </mat-menu>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #filterActionsMenu xPosition="before">

  <button type="button" mat-menu-item (click)="onFilterStopped()">
    <mat-icon svgIcon="image-broken"></mat-icon>
    <span i18n="@@ctrl_curriculum_show_stuck">Stuck curricula</span>
  </button>

  <mat-divider></mat-divider>

  <button type="button" mat-menu-item (click)="onResetFilter()" [disabled]="!isFilterChanged">
    <mat-icon svgIcon="filter-off-outline"></mat-icon>
    <span i18n="@@global_reset_filter">Reset filter</span>
  </button>

</mat-menu>

<ng-template #tplDataEmpty>
  <div class="no-data">
    <span i18n="@@ctrl_single_user_no_curricula">This user has not been assigned any curricula.</span>
  </div>
</ng-template>

<ng-template #tplDataLoading>
  <div class="no-data">
    <rag-loading-indicator
    ></rag-loading-indicator>
  </div>
</ng-template>

<ng-template #tplFilterDefault let-column="column">
  <rag-table-header-filter-default
    [column]="column"
    (filterChange)="onFilterChange($event.filter, column)"
  ></rag-table-header-filter-default>
</ng-template>

<ng-template #tplFilterDisplayStatus let-column="column">
  <rag-pivot-filter-account-display-status
    [filter]="column.options.filter"
    (change$)="onFilterChange($event, column)"
    [debounceTimeout]="0"
    defaultAction="$eq"
  ></rag-pivot-filter-account-display-status>
</ng-template>

<div
  *ngIf="isColumnContextLoaded && (renderColumns?.length > 0) else tplDataLoading"
  class="content"
>
  <rag-sticky-scroll [recalculate]="recalculateSticky$">
    <table
      *ngIf="hasData; else tplDataEmpty"
      [dataSource]="dataSource"
      mat-table
      matSort
    >

      <ng-container matColumnDef="batchCheckbox">
        <th *matHeaderCellDef class="batch-checkbox" mat-header-cell>
          <mat-checkbox
            (change)="onToggleAll()"
            [checked]="isSelectAllChecked()"
            [disabled]="isDataEmpty || !canChangeData"
            [indeterminate]="isSelectAllIndeterminate()"
          ></mat-checkbox>
        </th>
        <td *matCellDef="let row" class="batch-checkbox" mat-cell>
          <mat-checkbox
            (change)="onToggleSelection($event, row)"
            [checked]="isRowChecked(row) && hasMultiActions(row)"
            [indeterminate]="isRowIndeterminate(row)"
            [disabled]="!hasMultiActions(row)"
          ></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="batchCheckboxFilter">
        <th *matHeaderCellDef class="batch-checkbox" mat-header-cell></th>
      </ng-container>

      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id">
        <th *matHeaderCellDef mat-header-cell mat-sort-header>
          {{column.title | localeText}}
        </th>
        <td *matCellDef="let row" mat-cell class="data-column">
          <rag-tables-column-render-default
            [column]="column"
            [row]="row"
            [targetType]="getTargetType(row)"
          ></rag-tables-column-render-default>
        </td>
      </ng-container>
      <ng-container *ngFor="let column of renderColumns" [matColumnDef]="column.id + 'Filter'">
        <th *matHeaderCellDef mat-header-cell>
          <ng-container
            *ngTemplateOutlet="column.id === 'displayStatus' ? tplFilterDisplayStatus : tplFilterDefault; context: {column: column}"
          ></ng-container>
        </th>
      </ng-container>

      <ng-container matColumnDef="collapse">
        <th *matHeaderCellDef class="collapse" mat-header-cell></th>
        <td *matCellDef="let row" class="collapse" mat-cell>
          <ng-container
            *ngIf="asParent(row) as parentRow"
          >
            <button
              [disabled]="!isChildVisible(parentRow)"
              (click)="toggleParent(parentRow)"
              type="button"
              mat-icon-button
            >
              <mat-icon
                *ngIf="parentRow.$expanded"
                svgIcon="chevron-down"
              ></mat-icon>
              <mat-icon
                *ngIf="!parentRow.$expanded"
                svgIcon="chevron-right"
              ></mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="collapseFilter">
        <th *matHeaderCellDef class="orderIndex" mat-header-cell></th>
      </ng-container>

      <ng-container matColumnDef="orderIndex">
        <th *matHeaderCellDef class="orderIndex" mat-header-cell mat-sort-header>
          <span i18n="@@global_nr">No.</span>
        </th>
        <td *matCellDef="let row" class="orderIndex" mat-cell>
          <span
            *ngIf="getOrderIndex(row) as orderIndex"
          >{{orderIndex}}</span>
          <mat-icon
            *ngIf="isCurriculumStuck(row)"
            svgIcon="alert-outline"
            color="warn"
            i18n-matTooltip="@@ctrl_single_user_details_curriculum_warn_tooltip"
            matTooltip="There is a validity, but the content is currently not assigned."
            matTooltipPosition="above"
          ></mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="orderIndexFilter">
        <th *matHeaderCellDef class="orderIndex" mat-header-cell></th>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button
            (click)="tableColumnMenu.openMenu($event)"
            mat-icon-button
          >
            <mat-icon svgIcon="dots-vertical"></mat-icon>
          </button>
        </th>
        <td *matCellDef="let row" class="actions" mat-cell>
          <button
            *ngIf="isParent(row)"
            [disabled]="inputDisabled"
            [matMenuTriggerFor]="parentActionsMenu"
            [matMenuTriggerData]="{row: row}"
            mat-icon-button
          >
            <mat-icon svgIcon="menu"></mat-icon>
          </button>
          <button
            *ngIf="isChild(row)"
            [disabled]="!(maySave(row) || hasCourseActions(row))"
            [matMenuTriggerFor]="childActionsMenu"
            [matMenuTriggerData]="{row: row}"
            mat-icon-button
          >
            <mat-icon svgIcon="menu"></mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="actionsFilter">
        <th *matHeaderCellDef class="actions" mat-header-cell>
          <button mat-icon-button [matMenuTriggerFor]="filterActionsMenu">
            <mat-icon svgIcon="filter-outline"></mat-icon>
          </button>
        </th>
      </ng-container>

      <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
      <tr *matHeaderRowDef="columnsFilter; sticky: true" class="filter" mat-header-row></tr>
      <tr *matRowDef="let row; columns: columns; when: isParentRow" mat-row class="parent"
          [ngClass]="{'filter-no-match': !isFilterMatch(row)}"></tr>
      <tr *matRowDef="let row; columns: columns; when: isChildRow" mat-row class="child"
          [ngClass]="{'filter-no-match': !isFilterMatch(row)}"></tr>
    </table>

    <div class="no-data" *ngIf="isFilteredDataEmpty">
      <span i18n="@@global_no_data_filtered">No entries match your search criteria</span>
    </div>
  </rag-sticky-scroll>

  <!--<mat-paginator
    [disabled]="isDataEmpty"
    [pageSize]="20"
    [pageSizeOptions]="[5, 10, 20, 50, 100]"
    showFirstLastButtons
  ></mat-paginator>-->
</div>
