import { DisplayStatusHelper } from '../display-status-helper';
import { Translation } from '../translation/translation.types';
import { LanguageHelper } from '../language.helper';
import { sanitize } from 'dompurify';
import * as moment from 'moment';
import { AnyObject, Core } from '../core.types';
import { FilterResult, FilterResultDropDown } from './filter-api.types';
import { WithAction, WithIdentifier, WithValue } from '../primitives/primitives.types';
import { StringHelper } from '../primitives/string.helper';
import { TableHelper } from '../../component/table/table-helper';
import { DateHelper } from '../date.helper';
import { enumKeys, enumValues } from '../utils';
import { isMoment, Moment } from 'moment';


export type ColumnFilterMethod<ID = string, ROW_TYPE = any> =
  (data: ROW_TYPE, filter: ColumnFilterV2<ID>) => boolean;

export type ColumnFilterMethodAsync<ID = string, ROW_TYPE = any> =
  (filterResults: FilterResult[], data: ROW_TYPE, filter: ColumnFilterV2<ID>) => boolean;

export enum FilterOperator {
  AND = '$and',
  BETWEEN = '$between',
  ENDS_WITH = '$endsWith',
  EQ = '$eq',
  GT = '$gt',
  GTE = '$gte',
  IN = '$in',
  LIKE = '$like',
  LT = '$lt',
  LTE = '$lte',
  NE = '$ne',
  NOT = '$not',
  NOT_IN = '$notIn',
  NOT_LIKE = '$notLike',
  OR = '$or',
  STARTS_WITH = '$startsWith',
  NEXT_MONTHS = '$nextMonths',
  NEXT_DAYS = '$nextDays',
  PREVIOUS_MONTHS = '$previousMonths',
  PREVIOUS_DAYS = '$previousDays',
}

export const parseFilterOperator = (value: string): FilterOperator | null => {
  const keys = enumKeys(FilterOperator);
  for (const key of keys) {
    if (FilterOperator[key] === value) {
      return FilterOperator[key];
    }
  }

  const values = enumValues(FilterOperator);
  for (const val of values) {
    if (val === value) {
      return val;
    }
  }

  return null;
};

export interface ColumnFilterDropdownOption<T = string> {
  label: string;
  value: T;
}

export const dropdownOptionsFactory = (options: AnyObject<Translation>): ColumnFilterDropdownOption[] => Object.entries(options ?? {})
    .map(entry => ({ label: LanguageHelper.objectToText(entry[1]), value: entry[0] }));

export type ColumnFilterDataAccessor<DATA_TYPE, ID = string, RETURN_TYPE = any> =
  (entry: DATA_TYPE, options: ColumnFilterV2<ID, DATA_TYPE>) => RETURN_TYPE;

export interface ColumnFilterV2<ID = string, DATA_TYPE = any>
  extends WithAction<FilterOperator>, WithIdentifier<ID>, WithValue<DATA_TYPE> {
}

export const columnFilterFactory = <ID = string>(identifier: ID, action: FilterOperator = FilterOperator.EQ,
  defaultValue: string = null): ColumnFilterV2<ID> => ({
    identifier,
    action,
    value: defaultValue,
    defaultValue,
  });

export const splitFilterToStrings = ( value: string | null ): string[] | null => {
  if ( !value ) {
    return null;
  }

  const result: string[] = [];
  (value.match(/[^,;\s"]+|"(?:\\"|[^"])+"/g) || [])
    .map((s: string) => {
      s = s.replace(/["]/g, '');
      if ( s ) {
        result.push(s);
      }
    });
  if ( result.length ) {
    return result;
  }
  return null;
};

export const splitFilterToNumbers = ( value: string ): number[] | null => {

  const result = splitFilterToStrings(value);
  if ( result == null ) {
    return null;
  }

  return result
    .map(part => parseInt(part, 10))
    .filter(part => !isNaN(part));
};


// Filter for DisplayStatus
export const filterDisplayStatusV2: ColumnFilterMethod = (data: number | string, filter: ColumnFilterV2) => {
  if ( filter.value == null ) {
    return true;
  }
  if ( data == null ) {
    return false;
  }
  switch ( filter.value ) {
    case 'green':
      return DisplayStatusHelper.isStatusGreen(data);
    case 'red':
      return DisplayStatusHelper.isStatusRed(data);
    case 'yellow':
      return DisplayStatusHelper.isStatusYellow(data);
    case 'recertification':
      return DisplayStatusHelper.isStatusRecert(data);
    case 'not_green':
      return !DisplayStatusHelper.isStatusGreen(data);
    case 'not_red':
      return !DisplayStatusHelper.isStatusRed(data);
    default:
      // in case any direct comparison may work
      return String(filter.value) === String(data);
  }
};

export const filterTagsV2: ColumnFilterMethod = (data: string, filter: ColumnFilterV2) => {
  if ( data == null ) {
    return false;
  }

  const filterTags = TableHelper.splitTags(filter?.value);
  if ( !(filterTags.length > 0) ) {
    return true;
  }

  const contentTags = TableHelper.splitTags(data)
    .map(tag => tag.toLocaleLowerCase());
  return contentTags
    // check that contentTags contains all filterTags -> boolean and of all selected tags
    .filter(tag => filterTags.includes(tag)).length === filterTags.length;
};

// Text filter V2
export const filterTextV2: ColumnFilterMethod = (data: Translation, filter: ColumnFilterV2) => {
  const _data = LanguageHelper.objectToText(data)?.toLowerCase();
  const _filter = (filter.value ?? '').toLowerCase();
  if ( _data == null ) {
    return _filter.length === 0;
  }
  switch ( filter.action ) {
    case FilterOperator.EQ:
      return _data === _filter;
    case FilterOperator.STARTS_WITH:
      return _data.startsWith(_filter);
    case FilterOperator.ENDS_WITH:
      return _data.endsWith(_filter);
    case FilterOperator.NOT_LIKE:
      return _data.indexOf(_filter) === -1;
    case FilterOperator.IN: {
      const parts: string[] = splitFilterToStrings(_filter);
      if (!(parts?.length > 0)) {
        // filter is invalid -> anything matches
        return true;
      }

      for (const part of parts) {
        if ( _data.indexOf(part) >= 0 ) {
          // at least one filter parts is included in the data value -> match
          return true;
        }
      }

      return false;
    }
    case FilterOperator.NOT_IN: {
      const parts: string[] = splitFilterToStrings(_filter);
      if (!(parts?.length > 0)) {
        // filter is invalid -> anything matches
        return true;
      }

      for (const part of parts) {
        if ( _data.indexOf(part) >= 0 ) {
          // one of the parts matches exactly -> exclude data row
          return false;
        }
      }

      return true;
    }


    default:
      return _data.indexOf(_filter) >= 0;
  }
};

// HTML filter V2
export const filterHtmlV2: ColumnFilterMethod = (data: Translation, filter: ColumnFilterV2) => {
  const text = LanguageHelper.objectToText(data) ?? '';
  const sanitizedText = sanitize(text, { ALLOWED_TAGS: [] });
  return filterTextV2(sanitizedText, filter);
};

// Number filter V2
export const filterNumberV2: ColumnFilterMethod = (data: string, filter: ColumnFilterV2) => {

  if ( filter.value == null || filter.value === '' ) {
    // filter is empty -> anything matches
    return true;
  }

  const value = parseInt(data, 10);
  if ( isNaN(value) ) {
    // value is not a valid number
    return false;
  }

  switch ( filter.action ) {
    case FilterOperator.BETWEEN: {
      const split = /^(\d+)\s*-\s*(\d+)$/.exec(filter.value);
      if ( !(split && split.length === 3) ) {
        // not a valid value for filtering
        return true;
      }
      const from = parseInt(split[1], 10);
      const to = parseInt(split[2], 10);
      if ( from > to ) {
        // never matches
        return false;
      }
      return value >= from && value <= to;
    }
    case FilterOperator.EQ: {
      const filterValue = parseInt(filter.value, 10);
      return value === filterValue;
    }
    case FilterOperator.GT: {
      const filterValue = parseInt(filter.value, 10);
      return value > filterValue;
    }
    case FilterOperator.LT: {
      const filterValue = parseInt(filter.value, 10);
      return value < filterValue;
    }
    case FilterOperator.IN: {
      const parts: number[] = splitFilterToNumbers(filter.value);
      if ( !(parts?.length > 0) ) {
        // no valid numbers to filter -> anything matches
        return true;
      }
      return parts.includes(value);
    }
    case FilterOperator.NOT_IN: {
      const parts: number[] = splitFilterToNumbers(filter.value);
      if ( !(parts?.length > 0) ) {
        // no valid numbers to filter -> anything matches
        return true;
      }
      return !parts.includes(value);
    }
    default:
      return true;
  }
};

export const filterDateV2: ColumnFilterMethod = (data: any, filter: ColumnFilterV2) => {
  let filterValue: moment.Moment;
  let start: moment.Moment;
  let end: moment.Moment;
  if ((filter.action === FilterOperator.NEXT_MONTHS) || (filter.action === FilterOperator.NEXT_DAYS)) {
    if (data === null) {
      return false;
    }

    if ((typeof data != 'number') && !isMoment(data)) {
      return false;
    }

    const value = parseInt(filter.value, 10) ?? 0;
    const currentMoment = moment().startOf('day');
    const unit = filter.action === FilterOperator.NEXT_MONTHS ? 'months': 'days';
    let currentMomentPlusMonths = moment().add(value, unit).endOf('day');
    let dataMoment = data;
    if (typeof data === 'number') {
      dataMoment = moment(data);
    }
    dataMoment = (dataMoment as Moment);
    return dataMoment.isSameOrAfter(currentMoment) && dataMoment.isSameOrBefore(currentMomentPlusMonths);
  }
  if ((filter.action === FilterOperator.PREVIOUS_MONTHS) || (filter.action === FilterOperator.PREVIOUS_DAYS)) {
    if (data === null) {
      return false;
    }

    if ((typeof data != 'number') && !isMoment(data)) {
      return false;
    }

    const value = parseInt(filter.value, 10) ?? 0;
    const currentMoment = moment().startOf('day');
    const unit = filter.action === FilterOperator.PREVIOUS_MONTHS ? 'months': 'days';
    let currentMomentMinusMonths = moment().subtract(value, unit).endOf('day');
    let dataMoment = data;
    if (typeof data === 'number') {
      dataMoment = moment(data);
    }
    dataMoment = (dataMoment as Moment);
    return dataMoment.isSameOrBefore(currentMoment) && dataMoment.isSameOrAfter(currentMomentMinusMonths);
  }

  if (filter.action !== FilterOperator.BETWEEN) {
    filterValue = DateHelper.toMoment(filter.value);
    if (filterValue == null) {
      // no valid date could be found -> deactivate filter
      return true;
    }
  }

  if (filter.action === FilterOperator.BETWEEN) {
    const splitValue = filter?.value?.split(',') ?? [];
    start = DateHelper.toMoment(splitValue?.[0] ?? null);
    end = DateHelper.toMoment(splitValue?.[1] ?? null);
    if (!DateHelper.isMoment(start) || !DateHelper.isMoment(end)) {
      return true;
    }
  }

  let moment1: moment.Moment;
  if ( typeof data === 'number' && data > 2147483647 ) {
    data = data / 1000;
    moment1 = moment.unix(data);
  } else {
    moment1 = DateHelper.toMoment(data);
  }

  if ( !DateHelper.isValid(moment1) ) {
    return false;
  }

  switch ( filter.action ) {
    case FilterOperator.EQ:
      return moment1.isSame(filterValue, 'day');
    case FilterOperator.GTE:
      return moment1.isSameOrAfter(filterValue, 'day');
    case FilterOperator.LTE:
      return moment1.isSameOrBefore(filterValue, 'day');
    case FilterOperator.GT:
      return moment1.isAfter(filterValue, 'day');
    case FilterOperator.LT:
      return moment1.isBefore(filterValue, 'day');
    case FilterOperator.BETWEEN:
      return moment1.isSameOrAfter(start, 'day') && moment1.isSameOrBefore(end, 'day');
    default:
      console.error('Unsupported date filter action ' + filter.action);
  }
  return false;
};

export const filterDropDownV2: ColumnFilterMethod = (data: Translation, filter: ColumnFilterV2) => {
  if ( data == null ) {
    return false;
  }
  if ( filter.value == null ) {
    return true;
  }
  // values are generated from Object.keys and are always string -> must cast to string
  return data.toString() === filter.value.toString();
};

export const filterDropDownV2Results = <ID = string, DATA_TYPE = any>(
  filterResults: FilterResultDropDown[], targetId: number, targetType: string | Core.DistributableType,
  filter: ColumnFilterV2<ID, DATA_TYPE>) => {

  const filterValue = StringHelper.toString(filter?.value);
  if ( !filterValue ) {
    // filter not active -> do not filter anything
    return true;
  }

  if ( !(targetId > 0) || !targetType ) {
    // cannot match without target
    return false;
  }

  const result = filterResults
    ?.find(entry => entry?.option?.value === filterValue);
  if ( result == null ) {
    // results are still loading -> hide all entries for now
    return false;
  }

  return result.matches
      ?.find(match => (match?.targetId === targetId) && (match?.targetType === targetType))
    != null;
};
