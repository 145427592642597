import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { sphereDateSpanValidator } from 'src/app/core/ehs/sphere-date-span.validator';
import { destroySubscriptions, takeUntilDestroyed } from 'src/app/core/reactive/until-destroyed';
import { MyValidators } from 'src/app/core/validators';
import { MailEntry, TimeSettings } from '../../../../../core/mail.types';

@Component({
  selector: 'rag-time-settings',
  templateUrl: './time-settings.component.html',
  styleUrls: [ './time-settings.component.scss' ],
})
export class TimeSettingsComponent implements OnInit, OnDestroy {

  @Input() mailEntry: MailEntry;
  @Output() timeChanged = new EventEmitter<TimeSettings>();

  form: FormGroup;
  timeSettings = new TimeSettings();

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  static getDateIn15(input: Moment): Date {
    const result = input.clone()
      .milliseconds(0)
      .seconds(0);
    // set date to the next 15 minutes (at quarters)
    result.minutes((Math.floor((result.minutes()) / 15) + 1) * 15);
    return result.toDate();
  }

  dateTimeFilter(date: Moment): boolean {
    if (date === undefined) {
      return false;
    }
    return date.diff(moment(), 'days') > -1;
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  hasValue(controlName: string) {
    return this.form.get(controlName).value;
  }

  resetDate() {
    this.form.get('date').reset();
  }

  resetTime() {
    this.form.get('time').reset();
  }

  hasError(controlName: string, error: string) {
    if (this.form === undefined) {
      return false;
    }
    return this.form.get(controlName).hasError(error);
  }

  private buildForm() {

    this.form = this.formBuilder.group({
      date: [null],
      time: [null, [MyValidators.TimeValidator]],
      repetition: [null, [], [sphereDateSpanValidator(this.http, true)]]
    });

    if ( this.mailEntry ) {
      this.timeSettings.initWithMailEntry(this.mailEntry);
      if ( this.timeSettings.selectedDate ) {
        this.form.get('date').setValue(this.timeSettings.selectedDate);
        this.form.get('time').setValue(this.timeSettings.selectedTimeStr);
      }
      if ( this.mailEntry.resendInterval ) {
        this.form.get('repetition').setValue(this.mailEntry.resendInterval);
      }
    }

    this.form.get('time').statusChanges.pipe(map(status => {
      if (status === 'VALID') {
        this.form.get('repetition').enable();
      } else {
        this.form.get('repetition').reset();
        this.form.get('repetition').disable();
      }
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.form.get('date').valueChanges.pipe(map(value => {
      this.timeSettings.selectedDate = value;
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.form.get('repetition').valueChanges.pipe(map(value => {
      this.timeSettings.repetitionStr = value;
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.form.get('time').valueChanges.pipe(map(value => {
      this.timeSettings.selectedTime = null;
      this.timeSettings.selectedTimeStr = value;
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.form.statusChanges.pipe(map(status => {
      this.timeSettings.forceInvalid = status !== 'VALID';
      this.timeChanged.emit(this.timeSettings);
    }))
    .pipe(takeUntilDestroyed(this))
    .subscribe();

    this.timeChanged.emit(this.timeSettings);
  }

}
