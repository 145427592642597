import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiUrls } from '../../../../core/api.urls';
import { ApiResponse } from '../../../../core/global.types';
import {
  NotificationEventChangeDialogData, NotificationEventChangeDialogResponse,
  NotificationIdentifiable,
  NotificationTableData,
  SubTargetOptions,
  SubTargetsData,
} from './admin-notifications.types';
import { combineLatest, Observable, of } from 'rxjs';
import { AnyObject, Core } from '../../../../core/core.types';
import { ContentService } from '../../../../core/content/content.service';
import { map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import { AdminNotificationsEventChangeDialogComponent } from './admin-notifications-event-change-dialog/admin-notifications-event-change-dialog.component';
import { InfoService } from '../../../../core/info/info.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AdminNotificationsService {

  constructor(
    private contentService: ContentService,
    private http: HttpClient,
    private infoService: InfoService,
    private router: Router,
  ) {
  }

  fetchSubTargets(moduleId: number, targetId: number): Observable<SubTargetOptions[]> {
    if (targetId === undefined) {
      return of([]);
    }
    const negativeTargetId = targetId * -1;
    const url = ApiUrls.getKey('AdminEventActionsSubTargets')
      .replace(/{moduleId}/gi, String(moduleId))
      .replace(/{targetId}/gi, String(negativeTargetId));

    return this.http.get<SubTargetsData>(url)
      .pipe(map(data => data.subTargetOptions))
      .pipe(map(data => data
        ?.filter(entry => Math.abs(Number(entry.subTargetId)) !== targetId) ?? []));
  }

  getAllItemsWithType(objType?: Core.DistributableType): Observable<NotificationIdentifiable[]> {
    const url = ApiUrls.getKey('AdminNotificationsContents');
    return this.http.get<ApiResponse<AnyObject<NotificationIdentifiable[]>>>(url)
      .pipe(map(response => {
        const data = response.data ?? {};
        if (objType != null) {
          // return only the requested type
          return data[objType] ?? [];
        }
        return Object.values(data)
          .flatMap(o => o);
      }))
      // add legacy attribute
      .pipe(tap(data => data.forEach(o => o.objType = o.objectType)));
  }

  getNotificationList(filterType?: 'notification' | 'urlCall'): Observable<NotificationTableData[]> {
    const url = ApiUrls.getKey('AdminNotificationsList');

    return this.http.get<ApiResponse<NotificationTableData[]>>(url)
      .pipe(map(response => {
        const data = (response.data ?? []).map(entry => {
          entry.isNotification = (Number(entry.urlCallId) === -1);

          if (entry.targetId != null) {
            entry.targetId = String(Math.round(Number(entry.targetId)));
          }
          return entry;
        });

        if (filterType === 'notification') {
          return data.filter(entry => entry.isNotification);

        } else if (filterType === 'urlCall') {
          return data.filter(entry => !entry.isNotification);

        }
        return data;
      }));
  }

  onOpenCreateNotificationDialog(
    targetId: number, targetType: Core.DistributableType, moduleId: number, backUrl?: string,
  ): void {
    combineLatest([
      this.fetchSubTargets(moduleId, targetId),
      this.getAllItemsWithType(targetType),
    ])
      .pipe(switchMap(([subTargets, allItems]) =>
        this.infoService.showDialog<AdminNotificationsEventChangeDialogComponent, NotificationEventChangeDialogData,
      NotificationEventChangeDialogResponse>(AdminNotificationsEventChangeDialogComponent, {
        eventId: null,
        parentTargetId: targetId,
        currentTargetId: null,
        createMode: true,
        items: allItems,
        subTargets,
        defaultTemplateMode: false,
        moduleId: moduleId,
        elementDisabled: true,
      })
        .pipe(takeWhile(response => response != null))
        .pipe(switchMap(data => {
          const targetId = data?.subTargetId ? data?.subTargetId : data?.targetId;
          const url = `/admin/notifications/message/${targetType}/${targetId}/0`;
          const queryParams = {
            eventId: data.eventId,
            moduleId: data.eventModuleId,
          };

          if (backUrl != null) {
            queryParams['backUrl'] = backUrl;
          }

          if (data.subTargetId != null) {
            queryParams['parentTargetId'] = data.targetId;
          }

          const urlTree = this.router.createUrlTree([url], {
            queryParams
          });
          return this.router.navigateByUrl(urlTree);
        }))))
      .pipe(take(1))
      .subscribe();
  }
}
